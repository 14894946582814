/* Cards */

.section2-cards {
    padding: 8rem 0;
    background-color: #10aac51c;
}
.section2-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.section2-flexItem {
    width: 300px;
    text-align: center;
    padding: 1rem 0;
    -webkit-box-shadow: 10px 10px 5px 0px rgba(194, 192, 192, 0.034);
    -moz-box-shadow: 10px 10px 5px 0px rgba(192, 192, 192, 0.034);
    box-shadow: 10px 10px 5px 0px rgba(143, 143, 143, 0.034);
    border-radius: 1rem;
}


.section2-flexItem img {
    width: 100%;
    height: auto;
    z-index: -1;
}


.section2-flexItem h1 {
    font-size: 1rem;
    text-align: center;
    padding: 1rem;
    font-family: 'Roboto', sans-serif;
}

.section2-flexItem p {
    color: rgb(24, 23, 23);
    text-align: center;
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
}


@media screen and (max-width:700px)  {
    .section2-flexItem {
        width: 80%;
    }
}