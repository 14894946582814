@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.contactform {
    padding: 3rem 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.contact-container {
    width: 100%;
    margin: auto;
}

.contact2-header h1, .contact2-header p {
  font-family: 'Montserrat', sans-serif;
}


.contact-form-content {
  background-color: #85bcc4d0;
  padding: 1rem;
  border-radius: .5rem;
  width: 100%;
  margin-top: 3rem;
}


.topic-text h1 {
  color: rgb(243, 237, 237);
  font-weight: normal;
  padding: 1rem 0;
}

.form-hide h2 {
  font-weight: normal;
  color: rgb(243, 237, 237);
  font-size: 1rem;
}

.contact-form-content .right-side .topic-text p{
  font-size: 12px;
  font-weight: 400;
  color: rgb(243, 237, 237);
  font-family: 'Montserrat', sans-serif;
  text-align: start;
}

.radio-button p {
  text-align: left;
  font-size: 13px;
  padding-left: .2rem;
  color: white;
}

.input .radio-ans {
  text-align: left;
}


.right-side .input-box {
  height: 40px;
  width: 100%;
  margin: 12px 0;
}

.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  background: #ffffff;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}

.right-side .message-box{
  min-height: 110px;
}

.right-side .input-box textarea {
  padding-top: 6px;
}

.right-side .button{
  display: inline-block;
  margin-top: 12px;
}

.right-side .contact-btn {
  color: #22707cd0;
  font-size: 18px;
  outline: none;
  border: none;
  padding: .9rem 3rem;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.right-side .contact-btn:hover {
  background: rgb(223, 149, 64);
  color: white;
}


.disclaimer p {
  padding: 1rem 0;
  font-size: 12px;
  color: rgb(250, 245, 245);
}

.disclaimer span {
  padding: 1rem 0;
  font-size: 12px;
  color: rgb(29, 103, 201);
}

.dob p {
  color: white;
  font-size: 13px;
}

.dob label {
  padding: 0;
}

.contactImage {
    width: 100%;
    margin: auto;

}

.contactImage img {
    width: 100%;
    margin: auto;
}

.radioBtn {
    display: flex;
}


@media (max-width: 940px) {

  .form-hide {
    display: block;
  }

  .contactform {
    flex-wrap: wrap;
}


  .contact2-container {
    padding: 1rem ;
  }
  .contact2-container .content .right-side {
   width: 75%;
   margin-left: 55px;
}
  .contact2-container .contact-form-content{
    flex-direction: column-reverse;
    margin: 0rem 0;
  }

 .contact2-container .contact-form-content .right-side{
   width: 100%;
   margin-left: 0;
 }

 .contact2-container .contact-form-content .left-side{
  width: 100%;
}

 .topic-text-p {
  text-align: left;
 }

}



@media (max-width: 700px) {
  .contact-card {
    width: 80%;
  }


}


