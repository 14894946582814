.section1 {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: rgba(29, 28, 29, 0);
  }
  
  .section1:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .08;
  }

.section1-content {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 5rem 1rem;
}

.section1-left {
    width: 100%;
    margin: auto;
}

.section1-left img {
    width: 100%;
    height: auto;
}

.section1-right {
    width: 100%;
    margin: auto;
}


.section1-right h1 {
    font-family: 'Hallenger_Serif', sans-serif;
    font-size: 1.5rem;
    padding: 1rem 0;
}


.section1-right p {
    text-align: justify;
    font-family: 'Roboto', sans-serif;
    color: var(--primary-text);
    font-size: 15px;
}


.section1-link {
    text-align: center;    
}

.section1-link p span {
    font-size: 16px;
    color: var(--primary-color);
}

.section1-link p span:hover {
    color: orange;
}

.section2-btn {
    display: flex;
    gap: 1rem;
}

.section1-btn2 button {
    background-color: var(--primary-color);
    color: rgb(238, 231, 231);
    border: none;
    border-radius: 5px;
    padding: .8rem 2.5rem;
    font-size: .8rem;
}

.section1-btn1 button {
    background-color: var(--primary-color);
    color: rgb(238, 231, 231);
    border: none;
    border-radius: 5px;
    padding: .8rem 2.5rem;
    font-size: .8rem;
}



@media screen and (max-width:940px)  {

    .section1-content {
        flex-wrap: wrap;
    }
    

    .section1-right {
        width: 90%;
    }
    
    .section1-right h1 {
        text-align: center;
    }
    
}

@media screen and (max-width:700px) {

    .section2-btn {
        flex-wrap: wrap;
        gap: 0;
        justify-content: center;
    }
    .section1-right h1 {
        font-size: 1.2rem;
    }
}

