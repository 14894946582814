@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');

/* img tags */


@font-face {
  font-family: "Hallenger_Serif";
  src: local("Hallenger_Serif"),
   url("../fonts/Hallenger_Serif_Font.ttf") format("truetype");
  font-weight: bold;
 }



.imagesContainer {
	width: 100%;
	height: 100dvh;
	position: relative;
	overflow: hidden;
}


.hero-caption {
  position: absolute;
  z-index: 5;
  bottom: 20%;
  left: 5%;
  padding: 1rem;
  width: 50%;
}

.hero-caption span {
  color: #d3d01e;
}

.hero-caption h1 {
  color: rgb(255, 255, 255);
  font-size: 6rem;
  font-family: 'Hallenger_Serif', sans-serif;
  line-height: 5rem;
}

.hero-caption h3 {
  color: rgb(255, 255, 255);
  font-size: 2.5rem;
  font-family: 'Hallenger_Serif', sans-serif;
}

.hero-caption p {
  color: white;
  font-size: 1rem;
  padding: 1rem 0;
}

.hero-caption h2 {
  color: white;
  font-size: 1.2rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 1.5rem 0 0 0;
  font-weight: normal;
}

.imagesContainer img {
	width: 100%;
  height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

/* images using CSS */
.imageDiv {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-size: cover;
}

.image1 { background: linear-gradient(to top, #4b919456, #29363656), url('../../assets/hero_1.webp')no-repeat center center/cover; }

.image2 { background: linear-gradient(to top, #4b919456, #29363656), url('../../assets/hero_2.webp')no-repeat center center/cover; }


/* animation styles */
@keyframes fadeIn {
	0% { opacity: 0; }
	20% { opacity: 0; }
	60% { opacity: 1; }
	100% { opacity: 1; }
}

.fadeInClass {
	animation-name: fadeIn;
	animation-duration: 7s;
	animation-iteration-count: infinite;
	animation-direction: alternate;
	animation-timing-function: linear;
}


@media screen and (max-width: 940px) {
  .hero-caption {
    width: 100%;
    bottom: 10%;
    left: 0%;
    text-align: center;
  }

  .hero-caption h1 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .hero-caption h2 {
    font-size: 1rem;
  }


}

