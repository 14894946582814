@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.TMSSection-wrap {
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(29, 28, 29, 0);    
  }
  
  .TMSSection-wrap:before {
    content: '';
    position: absolute;
    background: url('../../assets/bg1.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .06;
  }


  .TMSSection-wrap-content {
    padding: 5rem 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }


.TMSSection-left {
    width: 600px;
    margin: auto;
    padding: 1rem;
}

.TMSSection-left img {
    width: 100%;
    border-radius: 1rem;
}

.TMSSection-right {
    width: 50%;
    margin: auto;
    padding: 1rem;
}

.TMSSection-right h1 {
    text-align: left;
    font-size: 1rem;
}

.TMSSection-right p {
    font-size: 14px;
    text-align: justify;
    color: rgb(19, 18, 18);
}


/* MEDIA QUERIES */

@media screen and (max-width:940px) {

    .TMSSection-wrap-content {
        flex-wrap: wrap;
      }

    .TMSSection-right {
        width: 100%;
        right: 0;
        text-align: center;
    }

    .TMSSection-left {
        width: 700px;
        margin: auto;
        position: relative;
        left: 0;
        padding: 1rem;
    }

    .TMSSection-right p {
        font-size: 14px;
    }
}



