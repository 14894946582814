@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');


.navbar {
    width: 100%;
    height: auto;
    z-index: 10;
    background: #10aac500;
    position: absolute;
    text-align: center;
}

.nav-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
}

.nav-box ul {
  margin: auto 0;
}

.navbar-logo {
  z-index: 9999999;
  width: 270px;
  padding: 1rem;
}

.navbar-logo img {
  width: 100%;
}

.navbar .icon {
  font-size: 2rem;
  color: #fff;
}

.navbar .logo .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: var(--primary-color);
}


.hamburger {
    display: none;
    padding: 1rem;
}

.navbar .nav-menu {
    height: 100%;
    align-items: center;
    display: flex;
    padding: .8rem 0;
}
.navbar li {
    width: max-content;
    padding: .3rem 1rem;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}


.navbar li a {
  color: rgb(224, 226, 225);
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1.5px;
}


.navbar li a:hover {
  color: #e9c709;
  font-size: 16px;
}

  .menu-item .sub__menus li a {
    color: #ffffff;
    font-size: 13px;
    padding: -1rem ;
    font-family: 'Roboto', sans-serif;
  }

  .menu-item .sub__menus li a:hover {
    color: rgb(241, 141, 11) !important;
  }

  li .submenu-header {
    font-size: 14px;
    color: rgb(36, 131, 27);
    font-weight: bolder;
    text-decoration: underline;
    cursor: pointer;
  }


.nav-main-flex {
  background-color: rgba(255, 255, 255, 0.404);
  padding: 1px;
  border-radius: 10px;
}

.sub-flex {
  display: flex;
  width: 350px;
  background-color: #4b9194;
  border: 1px solid rgba(255, 255, 255, 0.486);
  border-radius: 5px;
  padding: 1rem;
  margin: .5rem;
}

.sub-flex-right {
  width: 400px;
  margin: auto;
  text-align: center;
}

.sub-flex-right li {
  width: 250px;
}

.sub-flex-right p {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.sub-flex-right li a {
  color: rgb(255, 255, 255);
}



/* -----------------------------------------------------> SUB MENU */
.sub__menus {
    position: absolute;
    display: none;
    background: #ffffff00;
    border: 1px solid #ffffff00;
    border-radius: 10px;
    /* width: 735px; */
    height: auto;
    left: -10rem;
    padding: 2px 0 0 0;
    margin-top: 6px;
    z-index: 1000;
  }


@media screen and (max-width: 1000px) {


      .navbar .hamburger {
        display: block;
        z-index: 15;
    }

    .show {
      display: block;
    }

    .hide {
      display: none;
    }
    
    .navbar li a {
      color: rgb(250, 250, 250);
    }

    .navbar .nav-menu {
        position: absolute;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        top: 0;
        left:-100%;
        align-items: center;
        justify-content: center;
        background: rgb(13 110 159);
        transition: 0.5s ease-in;
        color: white;
    }

    .navbar .active {
        left: 0;
    }

    .navbar li {
        font-size: 1rem;
    }

    .navbar button {
        font-size: 1.8rem;
    }

    .menu-item .sub__menus li a {
        font-size: 14px;
      }

      li .submenu-header {
        font-size: 14px;
      }

      #submenu-item li {
        padding: .2rem 1rem;
      }

      .sub__menus {
        width: 250px;
      }

      .nav-main-flex {
        display: block;
      }


      .sub__menus {
        width: 370px;
        left: -8rem;

      }

}

