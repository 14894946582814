.footer {
    width: 100%;
    height: 50vh;
    position: relative;
    background: #4b9194;
}

.footer:before {
    content:'';
    position: absolute;
    /* background: url('../../assets/hero-bg-1.jpg') no-repeat center center/cover; */
    height: 100%;
    width: 100%;
    z-index: -1;
}

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.footer .top .logo-footer {
    display: flex;
    align-items: center;
    width: 250px;
}

.logo-footer img {
    width: 100%;
    padding: 1rem;
    border-radius: .5rem;
}

.footer .top .icon {
    font-size: 3rem;
    margin-right: 1rem;
    color: white;
    cursor: pointer;
}

.footer-col {
    width: 200px;
}

.footer .col-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem;
    gap: 1rem;
    width: 100%;
}

.footer h1 {
    margin: 1rem 0;
    text-transform: uppercase;
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 1rem;
}


.footer p {
    margin: .5rem 0;
    color: white;
    text-align: center;
}




.copyright p {
    color: white;
    text-transform: uppercase;
    text-align: center;
}

@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }

    .footer .col-container {
        grid-template-columns: 1fr 1fr;
    }

    .footer form {
        grid-column: 1 / span 2;
    }

    /* .logo-footer img{
        width: 50%;
    } */
}


