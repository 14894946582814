@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');



.insuranceSection3-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.insuranceSection3-content-left {
    width: 700px;
    text-align: center;
    padding: 1rem;
}

.insuranceSection3-content-left img  {
    width: 100%;
    height: auto;
    padding: 1rem;
}

.insuranceSection3-content-right {
    width:50%;
    margin: auto;
    padding: 1rem;
}


.insuranceSection3-content-right h2 {
    font-size: 2rem;
    color: #5a5c82;
}

.insuranceSection3-content-right h1 {
    text-transform: uppercase;
    color: #977ab3;
    font-size: 1rem;
    letter-spacing: 8px; 
}

.insuranceSection3-content-right p {
    line-height: 25px;
    text-align: justify;
    font-size: 13px;
}

.insurance-section3-btn {
    text-align: center;
    padding: 2rem 0;
}

.insurance-section3-btn {
    text-align: left;
}

.insurance-section3-btn button {
    background-color: rgba(241, 141, 11, 0.726);
    color: rgb(238, 231, 231);
    border: none;
    border-radius: 5px;
    padding: .8rem 2.5rem;
    font-size: 1rem;
}


@media screen and (max-width:940px)  {

    .insuranceSection3-content {
        flex-wrap: wrap;
    }

    .insuranceSection3-content-left {
        border: none;
    }

    .insuranceSection3-content-right {
        width: 100%;
        padding: 0;
    }

    .insuranceSection3-content-right h1 {
        text-align: center;
    }

    .insuranceSection3-content-right h2 {
        font-size: 1.5rem;
        text-align: center;
    }

    .insurance-section3-btn {
        text-align: center;
    }


}

