@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.InsuranceSection {
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(29, 28, 29, 0);
  }
  
  .InsuranceSection:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .06;
  }


.insurance-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem 0;
}

.InsuranceSection-container {
    width: 100%;
    padding: 2rem 1rem;
}

.InsuranceSection-container p {
    padding: 1rem 0;
    font-family: 'Montserrat', sans-serif;
    text-align: justify;
    }

.InsuranceSection h1 {
    font-size: 2rem;
    color: var(--primary-color);
    font-family: 'Hallenger_Serif', sans-serif;
    text-align: center;
}

.InsuranceSection h2 {
    text-transform: uppercase;
    color: var(--primary-dark);
    font-size: 1rem;
    letter-spacing: 5px; 
    text-align: center;
}

.insurance-section-link {
    text-align: center;    
}

.insurance-section-link p {
    font-size: 16px;
    color: black;
    text-align: center;
}


.insurance-section-link p span {
    font-size: 16px;
    color: var(--primary-color);
}

.insurance-section-link p span:hover {
    color: orange;
}




@media  screen and (max-width:940px) {
    .InsuranceSection-container h1 {
        font-size: 1.5rem;
    }

    .InsuranceSection-container p {
        font-size: 14px;
    }


}






