.faq {
  width: 100%;
  height: auto;
  padding: 3rem 0;
}



.faqSection {
  padding: 3rem 0;
}

.faqSection-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}


.faqSection-flex-left {
  width: 50%;
  margin: auto;
  padding: 1rem;
}

.faqSection-flex-left h1 {
  padding: 1rem 0;
}

.faqSection-flex-left p {
  text-align: justify;
}


.faqSection-flex-right {
  width: 500px;
  margin: auto;
}

.faqSection-flex-right img {
  width: 100%;
  border-radius: 1rem;
}



.faqContent {
  width: 80%;
  padding: 0;
  margin: 0 auto;
}


.centerplease {
  margin: 0 auto;
  max-width: 270px;
  font-size: 40px;
  text-align: center;
  padding: 1rem 0 3rem 0;
}

.centerplease h1 {
  color: var(--primary-text);
}

.question {
  color: white;
}

/*Question*/
.question {
  position: relative;
  background: #4b9194;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width:100%;
  cursor: pointer;
}
/*Answer*/
.answers {
  padding: 0px 15px;
  margin: 5px 0;
  width:100%!important;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.questions:checked ~ .answers{
  height: auto;
  opacity: 1;
  padding: 15px;
}


.answers h5 {
  padding: .5rem 0;
  color: rgb(235, 139, 15);
}

.answers ul li {
  padding: .5rem 0;
}


/*FAQ Toggle*/
.plus {
  position: absolute;
  margin-left: 10px;
  z-index: 5;
  font-size: 2em;
  line-height: 100%;
  -webkit-user-select: none;    
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
  color: white;

}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.questions {
  display: none;
  
}


@media screen and (max-width:940px) {
  .faqSection-flex-left {
    width: 100%;
    margin: auto;
  }
  
}







